import { useTitleContext } from "@/Shared/Providers/TitleContext"
import { useEffect } from "react"

const useChangePageTitle = (title: string) => {
  const titleContext = useTitleContext()

  if (!titleContext) {
    throw new Error(
      "useChangePageTitle has to be used within <TitleContext.Provider>",
    )
  }
  const { setTitle } = titleContext
  const titleLabel = title

  useEffect(() => {
    setTitle(titleLabel)
    return () => setTitle()
  }, [setTitle, titleLabel])
}
export default useChangePageTitle
